.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 4rem auto;
  padding: 2.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  margin-bottom: 2rem;
  color: #2d3748;
  font-size: 1.875rem;
  font-weight: 600;
  text-align: center;
}

form {
  width: 100%;
  max-width: 320px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
  font-size: 0.875rem;
}

.form-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.form-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.login-button {
  width: 100%;
  padding: 0.875rem;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.login-button:hover {
  background-color: #357ABD;
  transform: translateY(-1px);
}

.forgot-password-link {
  color: #4A90E2;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s;
}

.forgot-password-link:hover {
  color: #357ABD;
  text-decoration: underline;
}

.error-message {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: #FEE2E2;
  border: 1px solid #FCA5A5;
  border-radius: 6px;
  color: #DC2626;
  font-size: 0.875rem;
  text-align: center;
}

.success-message {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: #D1FAE5;
  border: 1px solid #6EE7B7;
  border-radius: 6px;
  color: #047857;
  font-size: 0.875rem;
  text-align: center;
}

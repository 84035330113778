@import url('./font.css');

:root {
  --primary-color: #232256;
  --secondary-color: #0057A5;
  --tertiary-color: #7DAFCB;
  --accent-color: #8CA2D2;
  
  /* Update this color to match exactly with your logo */
  --logo-navy: #232256; /* Adjust this hex code to match the dark blue in your logo */
}

body {
  margin: 0;
  font-family: 'D1Font', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.trading-desk-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
}

.quote-box {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.quote-box h2 {
  color: var(--primary-color);
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
}

.subtitle {
  color: #666;
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  font-size: 0.9rem;
}

.trade-type-selector {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.trade-button {
  flex: 1;
  padding: 0.75rem;
  border: 2px solid var(--primary-color);
  background: white;
  color: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
}

.trade-button.active {
  background: var(--primary-color);
  color: white;
}

.trade-button:hover:not(.active) {
  background: #f8f9fa;
}

.trade-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.input-group {
  display: flex;
  gap: 0.5rem;
  position: relative;
  align-items: center;
}

.amount-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.currency-select {
  padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  min-width: 100px;
  font-size: 1rem;
  appearance: none;
  position: relative;
  cursor: pointer;
}

.selected-currency-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.for-label {
  text-align: center;
  color: #666;
  font-weight: 500;
  padding: 0.5rem;
}

.quote-button {
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.quote-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quote-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .trading-desk-container {
    padding: 1rem;
  }

  .quote-box {
    padding: 1.5rem;
  }

  .input-group {
    flex-direction: row;
    width: 100%;
  }

  .currency-select {
    width: auto;
    min-width: 100px;
  }
} 
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--logo-navy);
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, var(--logo-navy), rgba(35, 34, 86, 0.95));
  width: 100%;
  box-sizing: border-box;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  max-width: 1920px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.logo a:hover {
  color: var(--accent-color);
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links li:first-child {
  margin-left: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  position: relative;
  padding: 5px 0;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-links a:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.login-button {
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 8px 20px !important;
  border-radius: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.login-button:hover {
  background-color: white;
  color: var(--primary-color) !important;
  transform: translateY(-2px);
}

.login-button::after {
  display: none !important;
}

main {
  flex-grow: 1;
  padding-top: 70px;
  width: 100%;
  max-width: 100%;
}

.hero {
  position: relative;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  background: linear-gradient(135deg, rgba(35, 34, 86, 0.9), rgba(0, 87, 165, 0.9)), url('./images/background.png');
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.hero .wave .shape-fill {
  fill: white;
}

.services {
  position: relative;
  padding: 150px 2rem 150px;
  background-color: white;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

.services .wave {
  top: 100%;
  transform: translateY(-100%);
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-btn {
  background-color: #FFD700;
  color: var(--primary-color);
  border: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-btn:hover {
  background-color: #FFA500;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.services h2 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 3rem;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.service-item {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--accent-color);
  position: relative;
  overflow: hidden;
}

.service-item::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.1;
  transform: rotate(45deg);
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.service-item h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  position: relative;
}

.service-item p {
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
  position: relative;
}

footer {
  background-color: var(--primary-color);
  color: white;
  padding: 2rem;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.footer-info,
.footer-disclaimer {
  width: 48%;
}

.footer-info p,
.footer-disclaimer p {
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.footer-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 2rem 0;
}

.footer-bottom {
  text-align: center;
}

.footer-bottom p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.footer-links a,
.social-icons a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover,
.social-icons a:hover {
  color: white;
}

.footer-links .separator {
  color: var(--accent-color);
  margin: 0 0.5rem;
}

.social-icons a {
  font-size: 1.5rem;
  margin-left: 1rem;
}

body {
  background: linear-gradient(to bottom, var(--accent-color) 0%, white 100%);
}

.logo span {
  color: white;
  font-weight: 400;
}

.logo span strong {
  font-weight: 700;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--primary-color);
  color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.account-types {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.account-type {
  text-align: center;
  padding: 1rem;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
}

.account-type:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
}

.account-type h3 {
  margin: 1rem 0;
  color: white;
}

.account-type p {
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.apply-btn {
  background-color: var(--accent-color);
  color: var(--primary-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.apply-btn:hover {
  background-color: var(--tertiary-color);
  color: white;
}

.about-us, .our-partners, .services {
  position: relative;
  padding: 100px 2rem;
  background-color: white;
  color: var(--primary-color);
  z-index: 1;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

.about-us::before, .services::before, .our-partners::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" fill="none" stroke="%23232256" stroke-width="0.5" stroke-opacity="0.1"/></svg>');
  opacity: 0.5;
  z-index: -1;
}

.about-us .container, .services .container, .our-partners .container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.about-us h2, .services h2, .our-partners h2 {
  color: var(--primary-color);
  margin-bottom: 2rem;
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
}

.about-us h2::after, .services h2::after, .our-partners h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

.about-us p, .our-partners p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.wave {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.hero .wave .shape-fill {
  fill: white;
}

.about-us .wave .shape-fill,
.services .wave .shape-fill,
.our-partners .wave .shape-fill {
  fill: var(--accent-color);
  opacity: 0.2;
}

.partner-logos::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" fill="none" stroke="%23232256" stroke-width="0.5" stroke-opacity="0.1"/></svg>');
  opacity: 1;
  z-index: 1;
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 80px; /* Adjust based on your logo height */
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 0;
}

.ticker {
  display: inline-flex;
  white-space: nowrap;
  padding-right: 100%; /* Ensure the ticker content is wide enough */
  animation: ticker 60s linear infinite;
}

.ticker-item {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.ticker img {
  height: 60px;
  width: auto;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.ticker img:hover {
  opacity: 1;
  transform: scale(1.1);
}

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.partner-logos {
  background: linear-gradient(to left, rgba(var(--accent-color-rgb), 0.3), transparent);
  padding: 3rem 0;
  overflow: hidden;
  position: relative;
}

.login-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  color: var(--primary-color);
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-container button {
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: var(--secondary-color);
}

.login-container p {
  text-align: center;
  margin-top: 20px;
}

.login-container a {
  color: var(--primary-color);
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}

.register-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-popup-content a, .register-popup-content button {
  margin: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-popup-content button {
  background-color: #6c757d;
}

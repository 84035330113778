.App-header {
  background-color: #0056b3;
  padding: 1.2rem 2rem;
  width: 100%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  min-height: 40px;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 40px;
}

.nav-links li {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  height: 100%;
}

.nav-links a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;
}

.nav-links a:hover {
  color: #e0e0e0;
}

.login-button {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.4rem 1.2rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.95rem;
  line-height: 1;
}

.login-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hamburger {
  display: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #0056b3;
    padding: 1rem;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links ul {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .hamburger {
    display: block;
  }
}

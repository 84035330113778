.account-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.account-header {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.account-header h2 {
  color: #333;
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
}

.balance-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.currency-icon {
  color: var(--primary-color);
  font-size: 1.5rem;
}

.balance-amount {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--primary-color);
}

.transactions-section {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.transactions-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.filters {
  display: flex;
  gap: 1rem;
}

.date-filter {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
}

.search-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
}

.transactions-table-container {
  overflow-x: auto;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th {
  background: #f8f9fa;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #444;
  border-bottom: 2px solid #eee;
}

.transactions-table td {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.transaction-type {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.transaction-type.deposit {
  background: #e6f4ea;
  color: #1e7e34;
}

.transaction-type.withdrawal {
  background: #fbe9e7;
  color: #d32f2f;
}

.amount {
  font-weight: 500;
}

.amount.deposit {
  color: #1e7e34;
}

.amount.withdrawal {
  color: #d32f2f;
}

.status {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.status.completed {
  background: #e8eaf6;
  color: #3f51b5;
}

.status.pending {
  background: #fff3e0;
  color: #f57c00;
}

@media screen and (max-width: 768px) {
  .account-container {
    padding: 1rem;
  }

  .filters {
    flex-direction: column;
  }

  .search-input {
    width: 100%;
  }

  .transactions-table {
    font-size: 0.875rem;
  }
} 
.dashboard-header {
  background-color: var(--primary-color);
  padding: 0;
  width: 100%;
  border-bottom: none;
  height: 70px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, var(--logo-navy), rgba(35, 34, 86, 0.95));
}

.dashboard-header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  max-width: 1920px;
  margin: 0 auto;
}

.dashboard-header .logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.user-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.user-dropdown-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.user-dropdown-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.dropdown-icon {
  transition: transform 0.2s ease;
  opacity: 0.8;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 0.5rem;
  z-index: 1000;
  overflow: hidden;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-size: 1rem;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .dashboard-header {
    padding: 1rem;
  }

  .dashboard-header nav {
    width: 90%;
  }

  .dashboard-header .logo a {
    font-size: 1.2rem;
  }

  .user-dropdown-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 380px) {
  .dashboard-header .logo a span {
    font-size: 1rem;
  }

  .user-menu {
    margin-right: 0.5rem;
  }
} 
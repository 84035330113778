.get-started-popup {
  color: white;
  /* Add any other styles that should be consistent across all uses of this popup */
}

.popup-title {
  color: white;
  /* Add any other specific styles for the title */
}

/* Add any other necessary styles */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content.get-started-popup {
  background-color: #0056b3; /* Adjust this to match your main theme color */
  color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.popup-content.get-started-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-content.get-started-popup .popup-title {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.popup-content.get-started-popup .account-types {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.popup-content.get-started-popup .account-type {
  text-align: center;
  padding: 1rem;
  border: 1px solid #007bff; /* Lighter blue border */
  border-radius: 5px;
  background-color: #004085; /* Slightly darker blue for account type boxes */
}

.popup-content.get-started-popup .account-type h3 {
  color: white;
  margin: 0.5rem 0;
}

.popup-content.get-started-popup .account-type p {
  color: #cce5ff; /* Light blue for better readability */
  margin-bottom: 1rem;
}

.popup-content.get-started-popup .apply-btn {
  background-color: #007bff; /* Main theme blue for button */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-content.get-started-popup .apply-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

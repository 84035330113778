.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  margin: 4rem auto;
  max-width: 400px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.password-reset-container h2 {
  margin-bottom: 2rem;
  color: #2d3748;
  font-size: 1.875rem;
  font-weight: 600;
}

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.form-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  background-color: #f7fafc;
}

.form-group input:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
  background-color: white;
}

.form-group input::placeholder {
  color: #a0aec0;
}

.reset-button {
  margin-top: 2rem;
  width: 100%;
  padding: 0.875rem;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  transition: all 0.2s;
}

.reset-button:hover {
  background-color: #357ABD;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.back-button {
  width: 100%;
  padding: 0.875rem;
  background-color: #f7fafc;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  transition: all 0.2s;
}

.back-button:hover {
  background-color: #edf2f7;
  transform: translateY(-1px);
}

.success-message {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  color: #2f855a;
  background-color: #f0fff4;
  border: 1px solid #9ae6b4;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  color: #c53030;
  background-color: #fff5f5;
  border: 1px solid #feb2b2;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  width: 100%;
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.password-strength-indicator {
  height: 4px;
  background-color: #e2e8f0;
  border-radius: 2px;
  margin-top: 4px;
  overflow: hidden;
}

.strength-bar {
  height: 100%;
  width: 0;
  transition: all 0.3s ease;
}

.requirements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.requirement-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: #f8fafc;
  transition: all 0.3s ease;
}

.requirement-item.met {
  background-color: #f0fff4;
  color: #2f855a;
}

.requirement-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  transition: all 0.3s ease;
}

.requirement-icon.met {
  color: #28a745;
  transform: scale(1.1);
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in;
}

.animate-shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

.password-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.loading {
  position: relative;
  cursor: wait;
}

.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.password-requirements {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 2rem;
}
.dashboard-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;
}

.dashboard-container {
  display: flex;
  flex: 1;
  background-color: #f5f7fa;
  margin: 0;
  padding: 0;
}

.dashboard-sidebar {
  width: 200px;
  background-color: white;
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.sidebar-link {
  padding: 0.875rem 1.5rem;
  color: #4a5568;
  text-decoration: none;
  transition: all 0.2s;
  font-weight: 500;
  border-left: 3px solid transparent;
}

.sidebar-link:hover {
  background-color: #f7fafc;
  color: #2d3748;
}

.sidebar-link.active {
  background-color: #ebf4ff;
  color: #4A90E2;
  border-left: 3px solid #4A90E2;
}

.dashboard-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .dashboard-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e2e8f0;
  }

  .sidebar-links {
    padding: 1rem 0;
  }

  .sidebar-link {
    padding: 0.75rem 1.5rem;
  }

  .dashboard-content {
    padding: 1rem;
  }
} 
/* Regular */
@font-face {
    font-family: 'D1Font';
    src: url('./fonts/ESBuildTRIAL-Regular-BF6618ac1e2c29f.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Bold */
  @font-face {
    font-family: 'D1Font';
    src: url('./fonts/ESBuildTRIAL-Bold-BF6618ac1e17d36.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  /* Italic */
  @font-face {
    font-family: 'D1Font';
    src: url('./fonts/ESBuildTRIAL-Italic-BF6618ac1d5c512.otf') format('opentype');;
    font-weight: normal;
    font-style: italic;
  }
  
  /* Bold Italic */
  @font-face {
    font-family: 'D1Font';
    src: url('./fonts/ESBuildTRIAL-BoldItalic-BF6618ac1e10819.otf') format('opentype');;
    font-weight: bold;
    font-style: italic;
  }